import React from 'react'
import { Container, Row, Col, Form, Table, Button } from 'react-bootstrap';
import Student from '../../assets/image/student.png';
import BottomNav from '../../Components/BottomNav/BottomNav';
import Header from '../../Components/Header/Header';

export default function Attendance() {
    
  return (
    <>
        <Container fluid className='m-0 p-0 overflow-hidden'>
            <Row>
                <Col lg={12}>
                    <Row>
                        <Col>
                            <Header/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='services-section'>
                                <Row>
                                    <Col xs={12}>
                                        <h3 className='title-inner'>Attendance</h3>
                                        <p className='sub-title-inner'>14-06-2022 | Wednesday</p>
                                    </Col>
                                    <Col xs={12}>
                                        <div className='attendance-bottom-line'></div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className='attendance-dropdown'>
                                            <Form.Select aria-label="Default select example">
                                                <option>Open this select menu</option>
                                                <option value="1" selected>6th Grade A Section</option>
                                                <option value="2">6th Grade B Section</option>
                                                <option value="3">6th Grade C Section</option>
                                            </Form.Select>
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className='attendance-bottom-line'></div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className='attendance-list-view'>
                                            <Table className='mb-3 '>
                                                <tbody>
                                                    <tr>
                                                        <td className='title p-l-50'>Name</td>
                                                        <td className='title-underline'>Present</td>
                                                        <td className='title-underline'>Absent</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='title'><img src={Student} alt="name"/> Amit</td>
                                                        <td>
                                                            <Form.Check
                                                                type="radio"
                                                                name="present"
                                                                className='text-center'
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Check
                                                                type="radio"
                                                                name="present"
                                                                className='text-center'
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='title'><img src={Student} alt="name"/> Amit</td>
                                                        <td>
                                                            <Form.Check
                                                                type="radio"
                                                                name="present"
                                                                className='text-center'
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Check
                                                                type="radio"
                                                                name="present"
                                                                className='text-center'
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='title'><img src={Student} alt="name"/> Amit</td>
                                                        <td>
                                                            <Form.Check
                                                                type="radio"
                                                                name="present"
                                                                className='text-center'
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Check
                                                                type="radio"
                                                                name="present"
                                                                className='text-center'
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='title'><img src={Student} alt="name"/> Amit</td>
                                                        <td>
                                                            <Form.Check
                                                                type="radio"
                                                                name="o-ds-deafult-1"
                                                                className='text-center'
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Check
                                                                type="radio"
                                                                name="o-ds-deafult-1"
                                                                className='text-center'
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='title'><img src={Student} alt="name"/> Amit</td>
                                                        <td>
                                                            <Form.Check
                                                                type="radio"
                                                                name="o-ds-deafult-1"
                                                                className='text-center'
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Check
                                                                type="radio"
                                                                name="o-ds-deafult-1"
                                                                className='text-center'
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='title'><img src={Student} alt="name"/> Amit</td>
                                                        <td>
                                                            <Form.Check
                                                                type="radio"
                                                                name="o-ds-deafult-1"
                                                                className='text-center'
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Check
                                                                type="radio"
                                                                name="o-ds-deafult-1"
                                                                className='text-center'
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='title'><img src={Student} alt="name"/> Amit</td>
                                                        <td>
                                                            <Form.Check
                                                                type="radio"
                                                                name="present"
                                                                className='text-center'
                                                            />
                                                        </td>
                                                        <td>
                                                            <Form.Check
                                                                type="radio"
                                                                name="present"
                                                                className='text-center'
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                       <div className='text-center mb-5 pb-2 d-flex justify-content-around' >
                                            <Button variant="outline-primary" className='attendance-btn'>Reset</Button>
                                            <Button variant="primary" className=' ml-3 attendance-btn'>Submit</Button>
                                       </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <BottomNav/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </>
  )
}
