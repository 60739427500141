import React from 'react';
import {Routes,Route} from "react-router-dom";
import BottomNav from './Components/BottomNav/BottomNav';
import Attendance from './pages/Attendance/Attendance';
import Login from './pages/Authentication/Login';
import Behaviour from './pages/Behaviour/Behaviour';
import Chat from './pages/Chat/chat';
import Home from './pages/Home/Home';
import HomeWork from './pages/HomeWork/HomeWork';
import Message from './pages/Message/Message';
import Splash from './pages/Splash/Splash';
import Test from './pages/test';

function App() {
  return (
    <>
    <Routes>
      <Route path='/' element={<Splash/>} />
      <Route path='/nav' element={<BottomNav/>} />
      <Route path='/login' element={<Login/>} />
      <Route path='/home' element={<Home/>} />
      <Route path='/test' element={<Test/>} />
      <Route path='/attendance' element={<Attendance/>} />
      <Route path='/behaviour' element={<Behaviour/>} />
      <Route path='/homework' element={<HomeWork/>} />
      <Route path='/message' element={<Message/>} />
      <Route path='/chat' element={<Chat/>} />
    </Routes>
    </>
  );
}

export default App;
