import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import Logo from "./../../assets/image/svg/zunior_logo.svg";
import handWithChalk from "./../../assets/image/hand with chalk.png";
import curve from "./../../assets/image/curve1.png";
import {Link} from "react-router-dom";
export default function Splash() {
  return (
    <>
    <Container>
      <Row>
        <Col className='m-0 p-0'>
          <div className='splash-screen'>
            {/* <div className='mt-5 text-center'>
              <img src={Group} alt="group" />
            </div> */}
            <div className='screen-top'> 
                <img src={Logo} alt="screen" />          
              </div>
              <div className='screen-middle'> 
                <img src={handWithChalk} alt="hand" />          
              </div>
              <div className='screen-bottom'> 
                <img src={curve} alt="curve" className='w-100'/>  
                <div className='skip-text'><Link to="/login">Skip</Link></div>        
              </div>
          </div>
        </Col>
      </Row>
    </Container>
  
    </>
  )
}
