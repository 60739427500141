import React, {useState} from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import Teacher from "./../../assets/image/teacher.png";
import { Formik, Form, Field, ErrorMessage} from 'formik';
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import {Link} from "react-router-dom";

export default function Login() {

const [showPassword, setShowPassword] = useState(false);
const handleClick = () => setShowPassword(!showPassword);

  return (
    <>
        <Container fluid>
            <Row>
                <Col lg={12} className='m-0 p-0'>
                    <div className='login-bg'>
                        <img src={Teacher} alt="login" className='login-screen-top'/>
                    </div>
                    <div className='login-bottom'>
                        <p className='login-title'>Login</p>
                        <Formik
                            initialValues={{ phone: '', password: '' }}
                            // validate={values => {
                            //     const errors = {};
                            //     if (!values.email) {
                            //     errors.email = 'Required';
                            //     } else if (
                            //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            //     ) {
                            //     errors.email = 'Invalid email address';
                            //     }
                            //     return errors;
                            // }}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                alert(JSON.stringify(values, null, 2));
                                setSubmitting(false);
                                }, 400);
                            }}
                            >
                            {({ isSubmitting }) => (
                            <Form className='login-form'>
                                <label className='login-label'>Phone Number</label>
                                <Field type="text" name="phone" placeholder="+91 98765 43214" className="login-form-input"/>
                                <p className='get-otp-text'>Get OTP</p>
                                <ErrorMessage name="phone" component="div" />
                                
                                <label className='login-label mt-3'>Enter OTP</label>
                                <Field type="password" name="password" placeholder="xxxxxxxx" className='login-form-input'/>
                                <div onClick={handleClick}>
                                    {showPassword ?  <p className='show-password'><FaEyeSlash/></p> :  <p className='show-password'><FaEye/></p>}
                                </div>
                                <ErrorMessage name="password" component="div" />

                                <div className='text-center mt-3'>
                                    {/* <button type="submit" disabled={isSubmitting} className="btn login-btn">Login</button> */}
                                    <div className="btn login-btn"><Link to="/home">Login</Link></div>
                                </div>
                            </Form>
                            )}
                            </Formik>
                    </div>
                </Col>
            </Row>
        </Container>
    </>
  )
}
